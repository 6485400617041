import "../css/Logo.css";
import LogoSvg from "../assets/images/logo.svg";

function Logo({ isLink = false }) {
  return isLink ? (
    <a href="/" className="logo">
      <img src={LogoSvg} />
    </a>
  ) : (
    <div className="logo">
      <img src={LogoSvg} />
      {/* <LogoSvg /> */}
    </div>
  );
}

export default Logo;
