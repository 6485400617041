import Slider from "react-slick";
import "../css/ServicesTileContainer.css";
import ServiceTile from "./ServiceTile";
import SliderButton from "./SliderButton";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

function ServicesTileContainer({ tiles, translations, isMobile }) {
  const settings = {
    dots: true,
    centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    focusOnSelect: true,
    adaptiveHeight: true,
    customPaging: (i) => <div className="custom-dot"></div>,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          variableWidth: true,
        },
      },
    ],
  };

  return isMobile ? (
    <div className="servicesTileContainer">
      <Slider {...settings}>
        {tiles.map((tile, index) => (
          <ServiceTile
            key={index}
            image={tile.image}
            subheader={tile.subheader}
            header={tile.header}
            points={tile.points}
            price={tile.price}
            translations={translations}
          />
        ))}
      </Slider>
    </div>
  ) : (
    <div className="servicesTileContainer">
      {tiles.map((tile, index) => (
        <ServiceTile
          key={index}
          image={tile.image}
          subheader={tile.subheader}
          header={tile.header}
          points={tile.points}
          price={tile.price}
          translations={translations}
        />
      ))}
    </div>
  );
}

export default ServicesTileContainer;
