import "../css/SliderItem.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

function SliderItem({ image, title, text, link }) {
  return (
    <div className="sliderItem">
      <img src={image} alt={title} />
      <div className="itemData">
        <h3>{title}</h3>
        <p>{text}</p>
        <a href={link} className="itemButton">
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </a>
      </div>
    </div>
  );
}

export default SliderItem;
