import { useEffect, useState } from "react";
import "../css/LanguageSwitcher.css";
import PL from "../assets/images/flags/pl.jpg";
import UA from "../assets/images/flags/ua.jpg";
import UK from "../assets/images/flags/uk.jpg";
import RU from "../assets/images/flags/ru.jpg";
import LanguageOption from "./LanguageOption";
import LanguageList from "./LanguageList";

function LanguageSwitcher({ translations, setLanguage }) {
  const [activeLanguage, setActiveLanguage] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobileScreen(window.innerWidth <= 1420);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const LANGUAGES = {
    PL: { flag: PL, alt: translations.polishFlagAlt, country: "PL" },
    EN: { flag: UK, alt: translations.britishFlagAlt, country: "EN" },
    UA: { flag: UA, alt: translations.ukrainianFlagAlt, country: "UA" },
    RU: { flag: RU, alt: translations.russianFlagAlt, country: "RU" },
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem("preferredLanguage");
    let browserLanguage = navigator.language.slice(0, 2).toUpperCase();
    const defaultLanguage = "EN";

    if (browserLanguage == "RU") {
      browserLanguage = "UA";
    }

    if (savedLanguage && LANGUAGES[savedLanguage]) {
      setActiveLanguage(savedLanguage);
      setLanguage(savedLanguage.toLowerCase());
    } else if (LANGUAGES[browserLanguage]) {
      setActiveLanguage(browserLanguage);
      setLanguage(browserLanguage.toLowerCase());
    } else {
      setActiveLanguage(defaultLanguage);
      setLanguage(defaultLanguage.toLowerCase());
    }
    setIsInitialized(true);
  }, []);

  useEffect(() => {
    if (isInitialized) {
      localStorage.setItem("preferredLanguage", activeLanguage);
    }
  }, [activeLanguage, isInitialized]);

  if (activeLanguage === null) {
    return null;
  }

  const availableLanguages = isMobileScreen
    ? Object.values(LANGUAGES).filter((lang) => lang.country)
    : Object.values(LANGUAGES).filter(
        (lang) => lang.country !== activeLanguage
      );

  return (
    <div className="languageSwitcher">
      {!isMobileScreen && (
        <LanguageOption
          flag={LANGUAGES[activeLanguage].flag}
          alt={LANGUAGES[activeLanguage].alt}
          country={activeLanguage}
          isActive
        />
      )}
      <LanguageList
        availableLanguages={availableLanguages}
        setActiveLanguage={setActiveLanguage}
        setLanguage={setLanguage}
        activeLanguage={activeLanguage}
      />
    </div>
  );
}

export default LanguageSwitcher;
