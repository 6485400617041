import "../css/Header.css";
import NavButtonsContainer from "./NavButtonsContainer";
import NavButton from "./NavButton";
import LanguageSwitcher from "./LanguageSwitcher";
import Logo from "./Logo";
import HamburgerButton from "./HamburgerButton";
import MenuOverlay from "./MenuOverlay";

function Header({
  translations,
  setLanguage,
  isMobileScreen,
  isMenuActive,
  setIsMenuActive,
}) {
  const toggleMenu = () => {
    setIsMenuActive((prevState) => !prevState);
  };

  return isMobileScreen ? (
    <div className="headerWrapper" id="start">
      <header>
        <Logo isLink />
        <HamburgerButton toggleMenu={toggleMenu} />
        <MenuOverlay
          isMenuActive={isMenuActive}
          toggleMenu={toggleMenu}
          translations={translations}
          setLanguage={setLanguage}
        />
      </header>
    </div>
  ) : (
    <div className="headerWrapper" id="start">
      <header>
        <NavButtonsContainer type="header">
          <NavButton
            type="header"
            text={translations.aboutUsButton}
            href="#aboutus"
          />
          <NavButton
            type="header"
            text={translations.servicesButton}
            href="#services"
          />
        </NavButtonsContainer>
        <Logo isLink />
        <NavButtonsContainer type="header">
          <NavButton
            type="header"
            text={translations.portfolioButton}
            href="#portfolio"
          />
          <NavButton
            type="header"
            text={translations.contactButton}
            href="#contact"
          />
        </NavButtonsContainer>
      </header>
      <LanguageSwitcher translations={translations} setLanguage={setLanguage} />
    </div>
  );
}

export default Header;
