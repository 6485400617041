import "../css/FooterBottom.css";
import CopyrightItem from "./CopyrightItem";
import NavButton from "./NavButton";
import NavButtonsContainer from "./NavButtonsContainer";

function FooterBottom({ translations }) {
  return (
    <div className="footerBottom">
      <NavButtonsContainer type="footer">
        <NavButton
          type="footer"
          text={translations.aboutUsButton}
          href="#aboutus"
        />
        <NavButton
          type="footer"
          text={translations.servicesButton}
          href="#services"
        />
        <NavButton
          type="footer"
          text={translations.portfolioButton}
          href="#portfolio"
        />
        <NavButton
          type="footer"
          text={translations.contactButton}
          href="#contact"
        />
      </NavButtonsContainer>
      <CopyrightItem />
    </div>
  );
}

export default FooterBottom;
