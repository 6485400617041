import "../css/LanguageOption.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";

function LanguageOption({ flag, alt, country, isActive = false, onClick }) {
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobileScreen(window.innerWidth <= 1420);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const divclass = isActive ? "languageOption active" : "languageOption";

  return (
    <div className={divclass} onClick={onClick}>
      <img src={flag} alt={alt} />
      <span>{country}</span>
      {!isMobileScreen && isActive && (
        <span className="arrow">
          <FontAwesomeIcon icon={faAngleDown} />
        </span>
      )}
    </div>
  );
}

export default LanguageOption;
