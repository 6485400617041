import "../css/TimelineStepsContainer.css";
import TimelineStep from "./TimelineStep";

function TimelineStepsContainer({ steps }) {
  return (
    <div className="timelineStepsContainer">
      <ul>
        {steps.map((step, index) => (
          <TimelineStep
            key={index}
            number={step.number}
            title={step.title}
            text={step.text}
          />
        ))}
      </ul>
    </div>
  );
}

export default TimelineStepsContainer;
