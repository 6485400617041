import "../css/MenuOverlay.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Logo from "./Logo";
import NavButtonsContainer from "./NavButtonsContainer";
import NavButton from "./NavButton";
import LanguageSwitcher from "./LanguageSwitcher";

function MenuOverlay({ isMenuActive, toggleMenu, translations, setLanguage }) {
  const classNames = isMenuActive ? "active" : "hidden";

  return (
    <div className={`menuOverlay ${classNames}`}>
      <div className="overlayTop">
        <Logo isLink />
        <FontAwesomeIcon icon={faXmark} onClick={toggleMenu} />
      </div>
      <div className="overlayBottom">
        <NavButtonsContainer type="header">
          <NavButton
            type="header"
            text={translations.aboutUsButton}
            href="#aboutus"
            onClick={toggleMenu}
          />
          <NavButton
            type="header"
            text={translations.servicesButton}
            href="#services"
            onClick={toggleMenu}
          />
          <NavButton
            type="header"
            text={translations.portfolioButton}
            href="#portfolio"
            onClick={toggleMenu}
          />
          <NavButton
            type="header"
            text={translations.contactButton}
            href="#contact"
            onClick={toggleMenu}
          />
        </NavButtonsContainer>
        <LanguageSwitcher
          translations={translations}
          setLanguage={setLanguage}
        />
      </div>
    </div>
  );
}

export default MenuOverlay;
