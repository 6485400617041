import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import "../css/FooterTop.css";
import FooterContactItem from "./FooterContactItem";
import Logo from "./Logo";
import FooterSocialItem from "./FooterSocialItem";
import {
  faFacebook,
  faInstagram,
  faTelegram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";

function FooterTop({ translations }) {
  return (
    <div className="footerTop">
      <Logo />
      <div className="contactWrapper">
        <div className="contactItems">
          <FooterContactItem type="tel" href="+48 123 456 789" icon={faPhone} />
          <FooterContactItem
            type="mailto"
            href="contact@nightsitestudio.com"
            icon={faEnvelope}
          />
        </div>
        <div className="socialmediaItems">
          <p>{translations.socialMediaText}</p>
          <FooterSocialItem icon={faFacebook} href="https://facebook.com/" />
          <FooterSocialItem icon={faInstagram} href="https://instagram.com/" />
          <FooterSocialItem icon={faTiktok} href="https://tiktok.com/" />
          <FooterSocialItem icon={faTelegram} href="https://telegram.com/" />
        </div>
      </div>
    </div>
  );
}

export default FooterTop;
