import "../css/CopyrightItem.css";
import { useState, useEffect } from "react";
import axios from "axios";

function CopyrightItem() {
  const [year, setYear] = useState(null);

  useEffect(() => {
    axios
      .get("https://nightsitestudio.com/api/translations/year")
      .then((response) => {
        setYear(response.data.year);
      })
      .catch((error) => {
        console.error("There was an error fetching the year!", error);
      });
  }, []);

  return (
    <div className="copyrightItem">
      Copyright © {year} • Nightsite Studio • All rights resevred
    </div>
  );
}

export default CopyrightItem;
