import "../css/TypeSection.css";
import BusinessWeb from "../assets/images/businessweb.svg";
import EcommerceWeb from "../assets/images/ecommerceweb.svg";
import BlogWeb from "../assets/images/blogweb.svg";
import EduWeb from "../assets/images/eduweb.svg";
import SectionText from "./SectionText";
import TypeTileContainer from "./TypeTileContainer";
import SectionHeader from "./SectionHeader";
import SectionTop from "./SectionTop";

function TypeSection({ header, span, text, anchor, translations, isMobile }) {
  const tiles = [
    {
      image: BusinessWeb,
      title: translations.businessWebTitle,
      subtitle: "Modx, Wordpress",
      text: translations.businessWebText,
    },
    {
      image: EcommerceWeb,
      title: translations.onlineStoreTitle,
      subtitle: "Modx, Wordpress",
      text: translations.onlineStoreText,
    },
    {
      image: BlogWeb,
      title: translations.blogWebTitle,
      subtitle: "Modx, Wordpress",
      text: translations.blogWebText,
    },
    {
      image: EduWeb,
      title: translations.elearningPlatformTitle,
      subtitle: "Modx, Wordpress",
      text: translations.elearningPlatformText,
    },
  ];

  const mobileHeader = isMobile ? (
    header
  ) : (
    <>
      {header.split(" ")[0]}
      &nbsp;
      {header.split(" ")[1]}
      <br />
      {header.split(" ").slice(2).join(" ")}
    </>
  );

  return (
    <section className="typeSection" id={anchor}>
      <SectionTop header={mobileHeader} span={span} text={text} width={1200} />
      <TypeTileContainer tiles={tiles} isMobile={isMobile ? 1 : 0} />
    </section>
  );
}

export default TypeSection;
