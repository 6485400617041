import "../css/FirstSection.css";
import { ReactSVG } from "react-svg";
import BackgroundEllipse from "./BackgroundEllipse";
import Button from "./Button";
import SectionText from "./SectionText";

function FirstSection({ header, image, text, anchor, buttonText }) {
  return (
    <section className="firstSection" id={anchor}>
      <div className="ornament"></div>
      <div className="sectionContent">
        <h1>{header}</h1>
        <SectionText text={text} width={650} />
        <Button type="link" text={buttonText} href="#services" />
      </div>
      <div className="sectionImage">
        <ReactSVG src={image} />
      </div>
    </section>
  );
}

export default FirstSection;
