import "../css/TypeTile.css";
import { ReactSVG } from "react-svg";

function TypeTile({ title, subtitle, text, image }) {
  return (
    <div className="typeTile">
      <div className="tileTop">
        <h3>{title}</h3>
        <h4>{subtitle}</h4>
      </div>
      <div className="tileBottom">
        <div className="tileText">{text}</div>
        <div className="tileImage">
          <ReactSVG src={image} />
        </div>
      </div>
    </div>
  );
}

export default TypeTile;
