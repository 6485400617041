import "../css/SectionTop.css";
import SectionHeader from "./SectionHeader";
import SectionText from "./SectionText";

function SectionTop({ header, span, text, width }) {
  return (
    <div className="sectionTop">
      <SectionHeader text={header} span={span} />
      <SectionText text={text} width={width} />
    </div>
  );
}

export default SectionTop;
