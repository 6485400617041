import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../css/ContactInput.css";

function ContactInput({ type, name, id, placeholder, icon }) {
  return (
    <div className="contactInput">
      <FontAwesomeIcon icon={icon} />
      <input type={type} name={name} id={id} placeholder={placeholder} />
    </div>
  );
}

export default ContactInput;
