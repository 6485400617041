import "../css/MainContainer.css";
import SectionImage from "../assets/images/section1.svg";
import Header from "./Header";
import FirstSection from "./FirstSection";
import TypeSection from "./TypeSection";
import StepsSection from "./StepsSection";
import ServicesSection from "./ServicesSection";
import PortfolioSection from "./PortfolioSection";
import ContactSection from "./ContactSection";
import Footer from "./Footer";
import GoToUpButton from "./GoToUpButton";
import { useState, useEffect } from "react";

function MainContainer({ translations, setLanguage }) {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [isMenuActive, setIsMenuActive] = useState(false);

  useEffect(() => {
    if (isMenuActive) {
      document.querySelector("html").style.overflowY = "hidden";
    } else {
      document.querySelector("html").style.overflowY = "scroll";
    }
  }, [isMenuActive]);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobileScreen(window.innerWidth <= 1420);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  return (
    <div className="mainContainer">
      <Header
        translations={translations}
        setLanguage={setLanguage}
        isMobileScreen={isMobileScreen}
        isMenuActive={isMenuActive}
        setIsMenuActive={setIsMenuActive}
      />
      <div className="mainWrapper">
        <main>
          <FirstSection
            header="Glawnyi texst"
            image={SectionImage}
            text={translations.firstSecText}
            anchor="aboutus"
            buttonText={translations.selectPlanButton}
          />
          <TypeSection
            header={translations.typeSecHeader}
            span={translations.typeSecSpan}
            text={translations.typeSecText}
            translations={translations}
            isMobile={isMobileScreen}
          />
          <StepsSection
            header={translations.stepsSecHeader}
            span={translations.stepsSecSpan}
            text={translations.stepsSecText}
            translations={translations}
          />
          <ServicesSection
            header={translations.servicesSecHeader}
            span={translations.servicesSecSpan}
            text={translations.typeSecText}
            anchor="services"
            translations={translations}
            isMobile={isMobileScreen}
          />
          <PortfolioSection
            header={translations.portfolioSecHeader}
            text={translations.typeSecText}
            anchor="portfolio"
            translations={translations}
          />
          <ContactSection
            header={translations.contactSecHeader}
            span={translations.contactSecSpan}
            text={translations.typeSecText}
            anchor="contact"
            translations={translations}
          />
        </main>
        <Footer translations={translations} />
      </div>
      <GoToUpButton />
    </div>
  );
}

export default MainContainer;
