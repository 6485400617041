import "../css/Footer.css";
import BackgroundEllipse from "./BackgroundEllipse";
import FooterBottom from "./FooterBottom";
import FooterTop from "./FooterTop";

function Footer({ translations }) {
  return (
    <footer>
      <FooterTop translations={translations} />
      <FooterBottom translations={translations} />
    </footer>
  );
}

export default Footer;
