import "../css/NavButton.css";

function NavButton({ text, href, type, onClick }) {
  return (
    <a href={href} className={`navButton ${type}`} onClick={onClick}>
      {text}
    </a>
  );
}

export default NavButton;
