import "../css/Button.css";

function Button({ type = "button", onClick, text, href }) {
  return type === "link" ? (
    <a href={href} className="button" onClick={onClick}>
      {text}
    </a>
  ) : (
    <button type={type} className="button" onClick={onClick}>
      {text}
    </button>
  );
}

export default Button;
