import "../css/MessageArea.css";

function MessageArea({ name, id, placeholder }) {
  return (
    <div className="messageArea">
      <textarea
        name={name}
        id={id}
        placeholder={placeholder}
        rows="10"
      ></textarea>
    </div>
  );
}

export default MessageArea;
