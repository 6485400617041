import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../css/FooterSocialItem.css";

function FooterSocialItem({ icon, href }) {
  return (
    <a href={href} className="footerSocialItem">
      <FontAwesomeIcon icon={icon} />
    </a>
  );
}

export default FooterSocialItem;
