import "../css/ContactSection.css";
import ContactSectionImage from "../assets/images/contactsection.svg";
import ContactFormContainer from "./ContactFormContainer";
import SectionTop from "./SectionTop";
import { ReactSVG } from "react-svg";
import BackgroundEllipse from "./BackgroundEllipse";

function ContactSection({ header, span, text, anchor, translations }) {
  return (
    <section className="contactSection" id={anchor}>
      <SectionTop header={header} span={span} text={text} width={860} />
      <div className="sectionBottom">
        <ContactFormContainer translations={translations} />
        <ReactSVG src={ContactSectionImage} />
      </div>
    </section>
  );
}

export default ContactSection;
