import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

const TranslationContext = createContext();

export const TranslationProvider = ({ children }) => {
  const [translations, setTranslations] = useState(null);
  const [language, setLanguage] = useState("pl");

  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        const response = await axios.get(
          `https://nightsitestudio.com/api/translations?lang=${language}`
        );
        setTranslations(response.data);
      } catch (error) {
        console.error("Błąd podczas pobierania tłumaczeń:", error);
      }
    };

    fetchTranslations();
  }, [language]);

  if (!translations) {
    return;
  }

  return (
    <TranslationContext.Provider value={{ translations, setLanguage }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () => {
  return React.useContext(TranslationContext);
};
