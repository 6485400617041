import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../css/GoToUpButton.css";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

function GoToUpButton() {
  const [onTop, setOnTop] = useState(true);

  useEffect(() => {
    const showButton = () => {
      if (window.scrollY > 400) {
        setOnTop(false);
      } else {
        setOnTop(true);
      }
    };

    window.addEventListener("scroll", showButton);

    return () => {
      window.removeEventListener("scroll", showButton);
    };
  }, []);

  return (
    <a href="#start" className={`goToUpButton ${!onTop ? "active" : ""}`}>
      <FontAwesomeIcon icon={faArrowUp} />
    </a>
  );
}

export default GoToUpButton;
