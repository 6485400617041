import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../css/FooterContactItem.css";

function FooterContactItem({ type, href, icon }) {
  return (
    <div className="footerContactItem">
      <FontAwesomeIcon icon={icon} />
      <a href={type + `:` + href}>{href}</a>
    </div>
  );
}

export default FooterContactItem;
