import "../css/StepsSection.css";
import StepSection from "../assets/images/stepssection.svg";
import SectionHeader from "./SectionHeader";
import SectionText from "./SectionText";
import TimelineStepsContainer from "./TimelineStepsContainer";
import { ReactSVG } from "react-svg";
import BackgroundEllipse from "./BackgroundEllipse";
import SectionTop from "./SectionTop";

function StepsSection({ header, span, text, anchor, translations }) {
  const steps = [
    {
      number: 1,
      title: translations.firstStepTitle,
      text: "Pellentesque nec libero eget risus varius lacinia. Phasellus et tempor odio, eget porttitor velit. Nullam ullamcorper lacinia quam, ullamcorper phare",
    },
    {
      number: 2,
      title: translations.secondStepTitle,
      text: "Pellentesque nec libero eget risus varius lacinia. Phasellus et tempor odio, eget porttitor velit. Nullam ullamcorper lacinia quam, ullamcorper phare",
    },
    {
      number: 3,
      title: translations.thirdStepTitle,
      text: "Pellentesque nec libero eget risus varius lacinia. Phasellus et tempor odio, eget porttitor velit. Nullam ullamcorper lacinia quam, ullamcorper phare Pellentesque nec libero eget risus varius lacinia. Phasellus et tempor odio, eget porttitor velit. Nullam ullamcorper lacinia quam, ullamcorper phare Pellentesque nec libero eget risus varius lacinia. Phasellus et tempor odio, eget porttitor velit. Nullam ullamcorper lacinia quam, ullamcorper phare",
    },
    {
      number: 4,
      title: translations.fourthStepTitle,
      text: "Pellentesque nec libero eget risus varius lacinia. Phasellus et tempor odio, eget porttitor velit. Nullam ullamcorper lacinia quam, ullamcorper phare",
    },
    {
      number: 5,
      title: translations.fifthStepTitle,
      text: "Pellentesque nec libero eget risus varius lacinia. Phasellus et tempor odio, eget porttitor velit. Nullam ullamcorper lacinia quam, ullamcorper phare",
    },
  ];

  return (
    <section className="stepsSection" id={anchor}>
      <SectionTop header={header} span={span} text={text} width={860} />
      <div className="sectionBottom">
        <TimelineStepsContainer steps={steps} />
        <ReactSVG src={StepSection} />
      </div>
    </section>
  );
}

export default StepsSection;
