// import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import "../css/ContactFormContainer.css";
import Button from "./Button";
import ContactInput from "./ContactInput";
import { faPhone, faUser, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import MessageArea from "./MessageArea";

function ContactFormContainer({ translations }) {
  function sendMessage(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    const query = formData.get("query");
    console.log(`You searched for '${query}'`);
  }

  return (
    <div className="contactFormContainer">
      <h3>{translations.contactFormTitle}</h3>
      <form onSubmit={sendMessage}>
        <ContactInput
          type="text"
          name="fullName"
          id="fullName"
          placeholder={translations.fullNamePlaceholder}
          icon={faUser}
        />
        <ContactInput
          type="email"
          name="email"
          id="email"
          placeholder={translations.emailPlaceholder}
          icon={faEnvelope}
        />
        <ContactInput
          type="phone"
          name="phone"
          id="phone"
          placeholder={translations.phonePlaceholder}
          icon={faPhone}
        />
        <MessageArea
          name="message"
          id="message"
          placeholder={translations.messagePlaceholder}
        />
        <Button type="submit" text={translations.sendButton} />
      </form>
    </div>
  );
}

export default ContactFormContainer;
