import "../css/SectionText.css";

function SectionText({ text, width }) {
  const sectionStyle = {
    maxWidth: width + "px",
  };

  return (
    <div className="sectionText" style={sectionStyle}>
      {text}
    </div>
  );
}

export default SectionText;
