import "../css/App.css";
import BackgroundEllipse from "./BackgroundEllipse";
import MainContainer from "./MainContainer";
import { useTranslation } from "./TranslationProvider";

function App() {
  const { translations, setLanguage } = useTranslation();

  return (
    <div className="App">
      <BackgroundEllipse size="small" top={-300} left={-304} />
      <BackgroundEllipse size="big" top={-100} right={-200} />
      <BackgroundEllipse size="small" top={1100} left={-400} />
      <BackgroundEllipse size="small" top={1200} right={-400} />
      <BackgroundEllipse size="medium" top={2550} right={-50} />
      <BackgroundEllipse size="medium" top={3500} left={-400} />
      <BackgroundEllipse size="medium" top={3900} right={-600} />
      <BackgroundEllipse size="medium" top={4300} left={100} />
      <BackgroundEllipse size="medium" top={4800} right={-500} />
      <BackgroundEllipse size="medium" top={5200} left={-300} />
      {/* <BackgroundEllipse size="small" top={6300} left={-300} />
      <BackgroundEllipse size="medium" top={6500} right={-300} /> */}
      <MainContainer translations={translations} setLanguage={setLanguage} />
    </div>
  );
}

export default App;
