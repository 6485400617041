import "../css/PortfolioSectionSlider.css";
import SliderItem from "./SliderItem";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PortfolioSectionSlider = ({ items }) => {
  const settings = {
    dots: true,
    centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    focusOnSelect: true,
    adaptiveHeight: true,
    variableWidth: true,
    customPaging: (i) => <div className="custom-dot"></div>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          variableWidth: true,
        },
      },
    ],
  };

  return (
    <div className="portfolioSectionSlider">
      <Slider {...settings}>
        {items.map((item, index) => (
          <SliderItem
            key={index}
            image={item.image}
            title={item.title}
            text={item.text}
          />
        ))}
      </Slider>
    </div>
  );
};

export default PortfolioSectionSlider;
