import "../css/PortfolioSection.css";
import Test from "../assets/images/image.png";
import Test2 from "../assets/images/services/image.png";
import PortfolioSectionSlider from "./PortfolioSectionSlider";
import SectionTop from "./SectionTop";
import BackgroundEllipse from "./BackgroundEllipse";

function PortfolioSection({ header, text, anchor, translations }) {
  const items = [
    {
      id: 1,
      title: "Slide 1",
      text: "This is the first slide",
      image: Test,
    },
    {
      id: 2,
      title: "Slide 2",
      text: "This is the second slide",
      image: Test2,
    },
    {
      id: 3,
      title: "Slide 3",
      text: "This is the third slide",
      image: Test,
    },
    {
      id: 4,
      title: "Slide 4",
      text: "This is the fourth slide",
      image: Test2,
    },
    {
      id: 5,
      title: "Slide 5",
      text: "This is the fifth slide",
      image: Test,
    },
    {
      id: 6,
      title: "Slide 6",
      text: "This is the sixth slide",
      image: Test2,
    },
  ];

  return (
    <section className="portfolioSection" id={anchor}>
      <SectionTop header={header} text={text} width={860} />
      <div className="sectionBottom">
        <PortfolioSectionSlider items={items} />
      </div>
    </section>
  );
}

export default PortfolioSection;
