import "../css/LanguageList.css";
import LanguageOption from "./LanguageOption";

const LanguageList = ({
  availableLanguages,
  setActiveLanguage,
  setLanguage,
  activeLanguage,
}) => {
  return (
    <div className="languageList">
      {availableLanguages.map((lang) => (
        <LanguageOption
          key={lang.country}
          flag={lang.flag}
          alt={lang.alt}
          country={lang.country}
          onClick={() => {
            setActiveLanguage(lang.country);
            setLanguage(lang.country.toLowerCase());
          }}
          isActive={activeLanguage == lang.country}
        />
      ))}
    </div>
  );
};

export default LanguageList;
