import "../css/ServicesSection.css";
import Mercury from "../assets/images/services/mercury.svg";
import Saturn from "../assets/images/services/saturn.svg";
import Jupiter from "../assets/images/services/jupiter.svg";
import ServicesTileContainer from "./ServicesTileContainer";
import SectionTop from "./SectionTop";
import BackgroundEllipse from "./BackgroundEllipse";

function ServicesSection({
  header,
  span,
  text,
  anchor,
  translations,
  isMobile,
}) {
  const tiles = [
    {
      image: Mercury,
      header: "Mercury",
      points: [
        {
          text: "Ullam et rhoncus",
        },
        {
          text: "Aliquam vitae commodo nunc ",
        },
        {
          text: "A eleifend nunc. In sceleris",
        },
      ],
      price: translations.mercuryPrice,
    },
    {
      image: Saturn,
      header: "Saturn",
      points: [
        {
          text: "Ullam et rhoncus",
        },
        {
          text: "Aliquam vitae commodo nunc ",
        },
        {
          text: "A eleifend nunc. In sceleris",
        },
      ],
      price: translations.saturnPrice,
    },
    {
      image: Jupiter,
      header: "Jupiter",
      points: [
        {
          text: "Ullam et rhoncus",
        },
        {
          text: "Aliquam vitae commodo nunc ",
        },
        {
          text: "A eleifend nunc. In sceleris",
        },
      ],
      price: translations.jupiterPrice,
    },
  ];

  return (
    <section className="servicesSection" id={anchor}>
      <SectionTop header={header} span={span} text={text} width={860} />
      <div className="sectionBottom">
        <ServicesTileContainer
          tiles={tiles}
          translations={translations}
          isMobile={isMobile ? 1 : 0}
        />
      </div>
    </section>
  );
}

export default ServicesSection;
