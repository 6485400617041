import "../css/BackgroundEllipse.css";

function BackgroundEllipse({ size, top, left, right }) {
  const ellipseSize = "backgroundEllipse" + " " + size;

  const style = {
    top: top + "px",
    ...(left !== undefined && { left: left + "px" }),
    ...(right !== undefined && { right: right + "px" }),
  };

  return <div className={ellipseSize} style={style}></div>;
}

export default BackgroundEllipse;
