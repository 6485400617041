import "../css/ServiceTile.css";
import { ReactSVG } from "react-svg";
import Button from "./Button";

function ServiceTile({ image, header, points, price, translations }) {
  return (
    <div className="serviceTile">
      <div className="tileImage">
        <ReactSVG src={image} />
      </div>
      <div className="tileContent">
        <h3>{header}</h3>
        <ul>
          {points.map((point, index) => (
            <li key={index}>{point.text}</li>
          ))}
        </ul>
        <div className="tilePrice">
          {translations.fromWord + " " + price + " " + translations.currency}
        </div>
      </div>
      <Button text={translations.selectButton} />
    </div>
  );
}

export default ServiceTile;
