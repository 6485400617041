import "../css/SectionHeader.css";

function SectionHeader({ text, span }) {
  return (
    <h2 className="sectionHeader">
      {text}
      <span>{span}</span>
    </h2>
  );
}

export default SectionHeader;
