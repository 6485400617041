import "../css/TimelineStep.css";

function TimelineStep({ number, title, text }) {
  return (
    <li className="timelineStep">
      <span className="timelineNumber">{number}</span>
      <div className="timelineContent">
        <div className="timelineTitle">{title}</div>
        <div className="timelineText">{text}</div>
      </div>
    </li>
  );
}

export default TimelineStep;
