import "../css/TypeTileContainer.css";
import Slider from "react-slick";
import TypeTile from "./TypeTile";
import SliderButton from "./SliderButton";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

function TypeTileContainer({ tiles, isMobile }) {
  const settings = {
    dots: true,
    centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    variableWidth: true,
    adaptiveHeight: true,
    customPaging: (i) => <div className="custom-dot"></div>,
  };

  return isMobile ? (
    <div className="typeTileContainer">
      <Slider {...settings}>
        {tiles.map((tile, index) => (
          <TypeTile
            key={index}
            title={tile.title}
            subtitle={tile.subtitle}
            text={tile.text}
            image={tile.image}
          />
        ))}
      </Slider>
    </div>
  ) : (
    <div className="typeTileContainer">
      {tiles.map((tile, index) => (
        <TypeTile
          key={index}
          title={tile.title}
          subtitle={tile.subtitle}
          text={tile.text}
          image={tile.image}
        />
      ))}
    </div>
  );
}

export default TypeTileContainer;
